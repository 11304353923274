
.content-view
  display: flex
  //justify-content: space-between
  gap: 4rem
  padding-bottom: 3rem

.booked-section
  position: relative
  padding-top: calc(3rem + 6px)
  padding-bottom: 3rem

  &::after
    content: ''
    position: absolute
    top: 0
    left: -3rem
    height: 6px
    background: var(--gray-200)
    width: calc(100% + 6rem)

::v-deep .main__content
  .slider__image
    object-fit: contain

::v-deep .slider-content
  margin: 1rem
  max-width: 42rem
  //margin-left: 1rem
  //margin-right: 1rem
  //margin-bottom: 1rem
  .swiper-pagination-bullets
    bottom: 0 !important

  .swiper-pagination-bullet
    width: 9px
    height: 10px

  .swiper-container
    display: flex
    align-items: center
    justify-content: center
    height: 24rem

    .swiper-slide
      cursor: grab
      display: flex
      justify-content: center
      align-items: center
      padding: 0 58px

      .swiper-image
        width: 100%
        max-height: 19rem
        object-fit: contain
    //height: 16rem

    .swiper-button
      width: 3rem
      height: 3rem
      border-radius: 50%
      background-color: var(--gray-100)

    .swiper-button-next::after,
    .swiper-button-prev::after
      content: none

  .swiper-pagination
    margin-top: 3rem

    &-bullets
      bottom: 1rem

    &-bullet
      width: 0.75rem
      height: 0.75rem
      margin-right: 0.3rem
      background-color: var(--gray-400)

      &-active
        background-color: var(--violet-400)

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

/* Firefox */
input[type="number"]
  -moz-appearance: textfield

.main__content
  padding-left: 1rem
  padding-right: 1rem
  min-width: 30rem

.main__class
  background-color: white
  padding: 3rem
  min-height: 100vh
  color: var(--gray-600)

.primary__information
  max-width: 42rem

.calculator
  margin-top: 3.5rem
  margin-left: 1rem
  margin-right: 1.5rem
  max-width: 500px

  h2
    font-family: 'CraftworkSans', serif
    font-weight: 900
    font-size: 24px
    line-height: 28px
    color: #9CA3AF

  &-title
    font-family: 'CraftworkSans', serif
    font-weight: 900
    font-size: 24px
    line-height: 28px
    color: #9CA3AF !important
    margin-bottom: 1.5rem

  .inputs
    margin-right: 2.25rem
    margin-bottom: 2.5rem

  .outputs
    margin-right: 2.25rem

    & > div
      margin-bottom: 2rem
      font-size: 18px
      font-weight: 600

      .total-price
        font-size: 24px

::v-deep .monthly
  background-color: var(--gray-100)
  border-radius: 2rem
  margin-top: 1.5rem
  display: none

  .placeholder
    letter-spacing: 1px
    color: var(--gray-400)
    text-transform: uppercase
    line-height: 10px
    font-size: 0.6rem
    margin-bottom: 0.25rem
    padding-top: 0.75rem
    padding-left: 1.5rem

  .input
    padding: 0.25rem 1.25rem 0.75rem 0
    margin-left: 1.5rem

    &::placeholder
      padding: 0

    &-monthly-payment
      border: none
      background-color: transparent

  .square-price
    padding: 0.75rem 1.25rem
    background-color: var(--gray-200)
    border-top: 2px solid var(--gray-300)
    border-bottom-right-radius: 1rem
    border-bottom-left-radius: 1rem

.discount-per-m2
  border-radius: 2rem
  background-color: var(--gray-100)
  margin-top: 1.5rem
  width: 100%

.checkout__button
  padding: 17px 32.75px

.action-block
  display: flex
  flex-wrap: wrap
  margin-top: 1rem
  margin-bottom: .5rem
  gap: .5rem

  .checkout__button
    padding: 12.75px

.print__button,
.cancel__button,
.view__button
  outline: none
  border: none
  width: 3.5rem
  height: 3.5rem
  border-radius: 50%

.contract_view_btn
  height: 3.5rem
  border-radius: 2rem
  padding: 1rem 2rem
  background-color: var(--blue-600)

::v-deep .apartment__details
  &-title
    display: none

  &-row
    .property
      text-transform: none

.peculiarities
  margin-top: 48px

  h1
    font-family: 'CraftworkSans', serif
    font-weight: 900
    font-size: 18px
    line-height: 22px
    color: #9CA3AF

::v-deep .promo__section
  border: none
  border-bottom: 3px solid var(--gray-100)

::v-deep .promo__section:last-child
  border: none !important

.list-boxes
  gap: 16px

.price__section
  font-family: CraftworkSans, serif
  font-size: 1.5rem
  font-weight: 900
  margin: 1.5rem 0

.promo__section-subtitle
  text-transform: capitalize

  &-amount
    color: var(--gray-600) !important
    line-height: 28px

  &-square-amount
    color: var(--gray-500) !important
    font-size: 18px
    line-height: 22px

.no_promos
  font-family: 'Inter', serif
  font-weight: 600
  font-size: 18px
  line-height: 24px
  color: #9CA3AF
  margin-top: 24px
  margin-bottom: 48px

//@media only screen and (max-width: 1200px)
//  .content-view
//    flex-wrap: wrap
//    row-gap: 2rem
//    justify-content: center !important
//  .calculator
//    max-width: 640px
//  //.primary__information
//  //  width: 30rem
//  .price__section
//    font-size: 1.2rem
//
//    &-amount
//      line-height: 25px
//
//    &-square-amount
//      font-size: 15px
//      line-height: 18px
@media (max-width: 1350px)
  .content-view
    flex-wrap: wrap
  .calculator
    max-width: 42rem
